import * as Sentry from "@sentry/react";
import leadService, { Lead } from "services/leadService";
import { Company, Me } from "types";


export type ConversionAffilae = {
  id: string,
  amount: string,
  payment: string
}

export interface AeEvent {
  key: string,
  Conversion: ConversionAffilae
}


const identifyUser = (me: Me) => {
  // Sentry
  Sentry.configureScope(function (scope) {
    scope.setUser({ email: me.email });
  });

  // Crisp
  window.CRISP_TOKEN_ID = me.crispToken;
  window.$crisp.push(["do", "session:reset"]);
  if (process.env.NODE_ENV === "production") {
    window.$crisp.push(["safe", true]);
  }
  window.$crisp.push(["set", "user:email", [me.email]]);
  window.$crisp.push([
    "set",
    "user:nickname",
    [`${me.firstName} ${me.lastName}`],
  ]);

  // Segment
  window.analytics.identify(me.id, {
    email: me.email,
    firstName: me.firstName,
    lastName: me.lastName,
    phone: me.phone,
  });
  if (me.company) {
    const { company } = me;
    window.analytics.group(company.id, {
      name: company.name,
      industry: company.businessKind,
      plan: company.plan,
    });
  }
};

const unidentifyUser = () => {
  // Crisp
  window.CRISP_TOKEN_ID = "";
  window.$crisp.push(["do", "session:reset"]);
  if (process.env.NODE_ENV === "production") {
    window.$crisp.push(["safe", true]);
  }
};

const hasConvertedForAppvizer = () => {
  window.av("conversion");
};

const hasRegistered = (me: Me) => {};

const hasVisited = (pathname: string) => {
  // Segment
  window.analytics.page(pathname);
};

const hasLogin = () => {};

const hasCategorized = () => {
  // Segment
  window.analytics.track("Transaction Categorized");
};

const hasBudgetized = () => {
  // Segment
  window.analytics.track("Budgets Edited");
};

const hasExported = () => {
  // Segment
  window.analytics.track("CashPlan Exported");
};

const hasOpenedPricing = () => {
  window.$crisp.push(["set", "session:event", ["user:pricing_opened"]]);

  // Segment
  window.analytics.track("Pricing Opened");
};

const hasChangedCompany = (company: Company) => {
  window.analytics.group(company.id, {
    name: company.name,
    industry: company.businessKind,
    plan: company.plan,
  });
};

const hasConvertedForAffilae = (lead: Lead) => {
  /* {{KEY}} must be updated for each rule */
  const aeEvent = {
    key:'653a2268c82b823117045d3e-653a1a66821410ac1adc7e87', //for programme partage 'lead essai gratuit'
    /* Values below must be updated */
    Conversion: {
      id: lead.uuid,
      amount: '0',
      payment: 'ONLINE',
      currency: 'EUR'
    }
  } as AeEvent
  // @ts-ignore: no type for AeTracker
  ('AeTracker' in window) ? AeTracker.sendConversion(aeEvent) : (window.AE = window.AE || []).push(aeEvent)
}

const marketingTools = {
  identifyUser,
  unidentifyUser,
  hasConvertedForAppvizer,
  hasRegistered,
  hasConvertedForAffilae,
  hasVisited,
  hasLogin,
  hasCategorized,
  hasBudgetized,
  hasExported,
  hasOpenedPricing,
  hasChangedCompany,
};

export default marketingTools;


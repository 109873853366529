import * as Sentry from "@sentry/react";
import { message } from "antd";
import { AxiosError } from "axios";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

export const errorHandler =
  (setError?: (errorMessage: ReactElement | undefined) => void) =>
  (e: AxiosError | any) => {
    const UNAUTHORIZED = 401;
    if (e?.response?.status === UNAUTHORIZED) {
      message.info("Votre session a expirée, veuillez vous reconnecter.");
    } else if (e?.message === "Network Error") {
      message.info(
        "Une erreur de réseau a été détectée. Êtes-vous connecté à internet ?"
      );
    } else if (e?.response?.data?.message) {
      message.error(e?.response?.data?.message);
      if (setError) {
        setError(<>{e?.response?.data?.message}</>);
      }
    } else if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
      if (setError) {
        setError(
          <FormattedMessage
            id={e?.response?.data?.message?.[0]?.messages?.[0]?.id}
            defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
            description="Server response to login"
          />
        );
      }
    } else {
      if (setError) {
        setError(
          <>
            Un problème est survenu. Veuillez rafraîchir la page ou essayer
            ultérieurement.
          </>
        );
      }

      console.error(e);
      if (process.env.NODE_ENV === "production") {
        Sentry.captureException(e);
      }
    }
  };

export const handleError = (e: AxiosError | any) => {
  const UNAUTHORIZED = 401;
  if (e?.response?.status === UNAUTHORIZED) {
    // message.info("Votre session a expirée, veuillez vous reconnecter.");
  } else if (e?.message === "Network Error") {
    message.info(
      "Une erreur de réseau a été détectée. Êtes-vous connecté à internet ?"
    );
  } else if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
    message.error(
      <FormattedMessage
        id={e?.response?.data?.message?.[0]?.messages?.[0]?.id}
        defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
        description="Server response to login"
      />
    );
  } else {
    message.error(
      "Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
    );

    console.error(e);
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(e);
    }
  }
};

export default errorHandler;

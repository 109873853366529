import { createContext, FC, ReactElement, useMemo, useState } from "react";

interface ErrorContextInterface {
  error: ReactElement | undefined;
  setError: (error: ReactElement | undefined) => void;
}

export const ErrorContext = createContext<ErrorContextInterface>({
  error: undefined,
  setError: () => {},
});

// Define the type for the children prop
type ErrorProviderProps = {
  children: ReactElement;
};

export const ErrorProvider: FC<ErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  const value = useMemo<ErrorContextInterface>(
    () => ({
      error,
      setError,
    }),
    [error, setError]
  );

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};
